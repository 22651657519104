import Swal from 'sweetalert2';
import moment from 'moment';
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../Shared/HelperMixin.vue'
export default {
    name: 'Student',
    mixins: [HelperMixin],
    async created() {

        await this.CheckLoginStatus();
        if (this.loginDetails.userType > 10)
            this.logout();

        await this.GetCities();
        await this.GetAcademicLevels();
        await this.GetPaymentMethods();
        await this.GetSupjectName();
        this.GetInfo();
    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            //return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            return moment(date).format('MMMM Do YYYY');
        },

         momentTime: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format('MMMM Do YYYY, h:mm:ss a');
            //return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {
            pageNo: 1,
            pageSize: 10, 
            pages: 0,
            state: 0,
            Info: [],
            studentId: '',
            FirstName: [],
            Students: [],
            Studentss: [],
            StudentWallet: [],
            StudentWalletPurchases: [],
            StudentCourses: [],
            StudentPhones: [],

            selectedItem: '',
            selectedStudentCourse: '',

            AddValueDiloag:false,
            AddStudentCourseDiloag:false,
            PayDiloag:false,
            SuspendDialog:false,


            config: {
                allowInput: true,
            },
            

            ruleForm: {
                Id: 0,
                FirstName: '',
                FatherName: '',
                GrandFatherName: '',
                SirName: '',

                LoginName: '',
                Gender: '',
                BirthDate: '',

                Phone: '',
                ExtraPhone: '',
                Email: '',
                CityId: '',
                MunicipalityId: '',
                LocationId: '',

                Value: 0,

                AcademicLevelId: '',
                AcademicSpecializationId: '',
            },
            rules: {
                FirstName: this.$helper.DynamicArabicEnterRequired('الاسم الاول'),
                FatherName: this.$helper.DynamicArabicEnterRequired('اسم الاب'),
                GrandFatherName: this.$helper.DynamicArabicEnterRequired('اسم الجد'),
                SirName: this.$helper.DynamicArabicEnterRequired(' اللقب'),


                LoginName: this.$helper.DynamicEnglishEnterRequired(' اسم الدخول'),
                Gender: this.$helper.Required(),
                BirthDate: this.$helper.Required(),

                Phone: this.$helper.NumberOnly(),
                ExtraPhone: this.$helper.NumberOnlyNotRequired(),
                Email: this.$helper.EmailNotRequierdOnly(),
                CityId: this.$helper.Required(),
                MunicipalityId: this.$helper.Required(),
                LocationId: this.$helper.Required(),

                Value: this.$helper.NumberOnlyRequired(),

                AcademicLevelId: this.$helper.Required(),
                AcademicSpecializationId: this.$helper.Required(),
            },


            WalletruleForm: {
                Id: 0,
                StudentId: '',
                PaymentMethodId: '',
                Value: '',
                ProcessType: 1,
            },
            Walletrules: {
                PaymentMethodId: this.$helper.Required(),
                Value: this.$helper.NumberOnly(),
            },

            StudentCourseruleForm: {
                Id: 0,
                StudentId: '',
                SubjectId: '',
                CourseId: '',
                Value: '',
            },
            StudentCourserules: {
                CourseId: this.$helper.Required(),
                Value: this.$helper.Required(),
            },

            PayruleForm: {
                Id: 0,
                Value: '',
            },
            Payrules: {
                Value: this.$helper.Required(),
            },

            SuspendForm: {
                StudentId: 0,
                Resone: '',
            },
            SuspendRules: {
                Resone: this.$helper.Required(),
            },
            
        };
    },
    methods: {

        OpenAddItemDilog() {
            this.state = 1;
        },

        async GetMunicipalitiesName() {
            this.ruleForm.MunicipalitId = '',
            await this.GetMunicipalities(this.ruleForm.CityId)
        },

        async GetLocationsName() {
            this.ruleForm.LocationId = '',
            await this.GetLocations(this.ruleForm.MunicipalityId)
        },

        async GetSupjectName() {
            this.StudentCourseruleForm.SubjectId = '',
                await this.GetSupjects()
        },

        async GetAcademicSpecializationName() {
            this.ruleForm.AcademicSpecializationId = '',
                await this.GetAcademicSpecialization(this.ruleForm.AcademicLevelId)
        },

        async GetCourseNameByAcademicSpecialization() {
            this.StudentCourseruleForm.CourseId = '',
                await this.GetCourseName(this.ruleForm.AcademicSpecializationId, this.StudentCourseruleForm.SubjectId)
        },

        submitForm(formName, type) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    if (type == 1) {
                        this.Add(formName);
                    } else {
                        this.Edit(formName);
                    }
                    
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        Add(formName) {
            this.ruleForm.Id = Number(this.ruleForm.Id);
            this.ruleForm.Value = Number(this.ruleForm.Value);
            this.$blockUI.Start();
            this.$http.AddStuent(this.ruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.GetInfo();
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.state = 0;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                });
        },

        GetInfo(pageNo) {
            this.ruleForm.Phone = '';
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.GetStudent(this.pageNo, this.pageSize, this.studentId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        GetStudentName() {
            this.StudentPhones = [];
            let code = "";
            if (document.getElementById('selectInputName') != null) {

                if (document.getElementById('selectInputName').value == null || document.getElementById('selectInputName').value == '')
                    return;

                code = document.getElementById('selectInputName').value;
            }

            if (code.length <= 3)
                return;

            this.$blockUI.Start();
            this.$http.GetStudentByStudentName(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.StudentPhones = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    //console.error(err);
                });
        },

        GetName() {
            this.StudentPhones = [];
            let code = "";
            if (document.getElementById('selectInput') != null) {

                if (document.getElementById('selectInput').value == null || document.getElementById('selectInput').value == '')
                    return;

                code = document.getElementById('selectInput').value;
            }

            if (code.length <= 4)
                return;

            this.$blockUI.Start();
            this.$http.GetStudentPhone(code)
                .then(response => {
                    this.$blockUI.Stop();
                    this.StudentPhones = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    //console.error(err);
                });
        },

        Refresh() {
            this.studentId = null;
            this.GetInfo();
        },


        OpenEdit(item) {
            this.state = 2;
            this.selectedItem = item;
            this.ruleForm.Id = item.id;
            this.ruleForm.FirstName = item.firstName;
            this.ruleForm.FatherName = item.fatherName;
            this.ruleForm.GrandFatherName = item.grandFatherName;
            this.ruleForm.SirName = item.sirName;
            
            this.ruleForm.LoginName = item.userInfo.loginName;
            this.ruleForm.Gender = item.userInfo.gender;
            this.ruleForm.BirthDate = item.userInfo.birthDate;

            this.ruleForm.Phone = item.userInfo.phone;
            this.ruleForm.ExtraPhone = item.userInfo.extraPhone;
            this.ruleForm.BirthDate = item.userInfo.birthDate;
            this.ruleForm.Email = item.userInfo.email;
        },

        Edit(formName) {
            this.$blockUI.Start();
            this.ruleForm.AcademicLevelId =0;
            this.ruleForm.AcademicSpecializationId = 0;
            this.ruleForm.LocationId = 0;
            this.ruleForm.AcademicLevelId = Number(this.ruleForm.AcademicLevelId);
            this.ruleForm.AcademicSpecializationId = Number(this.ruleForm.AcademicSpecializationId);
            this.ruleForm.LocationId = Number(this.ruleForm.LocationId);
            this.ruleForm.AcademicLevelId=Number()
            this.$http.EditStudent(this.ruleForm)
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.GetInfo();
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.state = 0;
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                });
        },
        
        Delete(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteStudent(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },

        ResetStudentPassword(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية تهيئة كلمة المرور ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.ResetStudentPassword(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },

        ResetPc(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية تغير جهاز الدخول   ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.ResetDeviecInfo(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },

        ResetLastLoginFrom(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية تغير مكان الدخول   ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.ResetLastLoginFrom(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },



        OpenStudentProfile(item) {
            this.selectedItem = item;
            this.GetStudentCourse(item.id);
            this.state = 3;
        },

        OpenAddStudentCourseDiloag() {
            this.AddStudentCourseDiloag = true;
            this.StudentCourseruleForm.CourseId = '';
            this.StudentCourseruleForm.Value = '';
            this.ruleForm.AcademicSpecializationId = '';
            this.ruleForm.AcademicLevelId = '';
            this.AcademicSpecialization = [];
        },
      
        submitCourseruleForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.StudentCourseruleForm.Id = Number(this.StudentCourseruleForm.Id);
                    this.StudentCourseruleForm.StudentId = Number(this.selectedItem.id);
                    this.StudentCourseruleForm.CourseId = Number(this.StudentCourseruleForm.CourseId);
                    this.StudentCourseruleForm.Value = Number(this.StudentCourseruleForm.Value);
                    this.$blockUI.Start();
                    this.$http.AddStudentCourse(this.StudentCourseruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.GetInfo();
                            this.GetStudentCourse(this.selectedItem.id);
                            this.GetStudentWalletInfo(this.selectedItem.id);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.AddStudentCourseDiloag = false;
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });

                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        GetStudentCourse(id) {
            this.$blockUI.Start();
            this.$http.GetStudentCourse(id)
                .then(response => {
                    this.$blockUI.Stop();
                    this.StudentCourses = response.data.info;
                    //this.StudentCourseCount=
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },


        OpenPayDiloag(item) {
            this.PayDiloag = true;
            this.selectedStudentCourse = item;
            this.PayruleForm.Value = '';
        },

        submitPayruleForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.PayruleForm.Id = Number(this.selectedStudentCourse.id);
                    this.PayruleForm.Value = Number(this.PayruleForm.Value);
                    this.$blockUI.Start();
                    this.$http.PayStudentCourse(this.PayruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.GetInfo();
                            this.GetStudentCourse(this.selectedItem.id);
                            this.GetStudentWalletInfo(this.selectedItem.id);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.PayDiloag = false;
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });

                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        OutFromCourse(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الإنسحاب سيتم إرجاع قيمة الدورة التدريبية للمحفظة ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.OutFromStudentCourse(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.GetStudentCourse(this.selectedItem.id);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },







        OpenWalletInfo(item) {
            //this.ValueDiloag = true;
            this.selectedItem = item;
            this.GetStudentWalletInfo(item.id);
            this.GetStudentWalletPurchasesInfo(item.id);
            this.state = 4;
        },

        OpenAddValueDiloag() {
            this.AddValueDiloag = true;
            this.WalletruleForm.Value = '';
            this.WalletruleForm.PaymentMethodId = '';
        },

        submitWalletruleForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.WalletruleForm.Id = Number(this.WalletruleForm.Id);
                    this.WalletruleForm.StudentId = Number(this.selectedItem.id);
                    this.WalletruleForm.Value = Number(this.WalletruleForm.Value);
                    this.WalletruleForm.ProcessType = Number(this.WalletruleForm.ProcessType);
                    this.$blockUI.Start();
                    this.$http.RechargeWallet(this.WalletruleForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.GetInfo();
                            this.GetStudentWalletInfo(this.selectedItem.id);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.AddValueDiloag = false;
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });

                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        GetStudentWalletInfo(id) {
            this.$blockUI.Start();
            this.$http.GetStudentWallet(id)
                .then(response => {
                    this.$blockUI.Stop();
                    this.StudentWallet = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        GetStudentWalletPurchasesInfo(id) {
            this.$blockUI.Start();
            this.$http.GetStudentWalletPurchases(id)
                .then(response => {
                    this.$blockUI.Stop();
                    this.StudentWalletPurchases = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        DeleteTransacitons(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteStudentWalletTransacitons(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.GetStudentWalletInfo(this.selectedItem.id);
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },


        OpenSuspendDialog(item) {
            this.selectedItem = item;
            this.SuspendDialog = true;
            this.SuspendForm.StudentId = '';
            this.SuspendForm.Resone = '';
        },

        submitSuspendForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.SuspendForm.StudentId = Number(this.selectedItem.id);
                    this.$blockUI.Start();
                    this.$http.SuspendStudent(this.SuspendForm)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.resetForm(formName);
                            this.GetInfo();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                            this.SuspendDialog = false;
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                        });

                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },

        ActiveAcountStudent(Id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية تفعيل الحساب   ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.ActiveAcount(Id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.GetInfo();
                            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$helper.ShowMessage('error', 'خطأ بعملية الحذف', err.response.data);
                        });
                    return;
                }
            })
        },
        

    }
}
