import axios from 'axios';

axios.defaults.headers.common['X-CSRF-TOKEN'] = document.cookie.split("=")[1];

//const baseUrl = 'http://localhost:4810/Api';

export default {




    //********************************************************************| Authintecations |***********************************************************

    login(bodyObjeect) {
        return axios.post(`/Security/login`, bodyObjeect);
    },

    IsLoggedin() {
        return axios.get(`/Security/IsLoggedin`);
    },

    Logout() {
        return axios.post(`/Security/Logout`);
    },



    GetDashboardInfo() {
        return axios.get(`/Api/Admin/Helper/GetDashboardInfo`);
    },

    GetChangeRequestCount() {
        return axios.get(`/Api/Admin/Helper/GetChangeRequestCount`);
    },




    //********************************************************************| Helper |***********************************************************
    GetCitiesName() {
        return axios.get(`/Api/Admin/Helper/GetCitiesName`);
    },

    GetMunicipalitiesName(id) {
        return axios.get(`/Api/Admin/Helper/GetMunicipalitiesName?id=${id}`);
    },

    GetLocationsName(id) {
        return axios.get(`/Api/Admin/Helper/GetLocationsName?id=${id}`);
    },

    GetAcademicLevelsName() {
        return axios.get(`/Api/Admin/Helper/GetAcademicLevelsName`);
    },
    GetAcademicSpecializationName(id) {
        return axios.get(`/Api/Admin/Helper/GetAcademicSpecializationName?id=${id}`);
    },

    GetCoursesName(academicSpecializationId,subjectId) {
        return axios.get(`/Api/Admin/Helper/GetCoursesName?AcademicSpecializationId=${academicSpecializationId}&SubjectId=${subjectId}`);
    },

    GetSupjectName() {
        return axios.get(`/Api/Admin/Helper/GetSupjectName`);
    },

    GetInstructorsName() {
        return axios.get(`/Api/Admin/Helper/GetInstructorsName`);
    },

    GetPaymentMethod() {
        return axios.get(`/Api/Admin/Helper/GetPaymentMethod`);
    },



    //********************************************************************| Stuents |***********************************************************
    GetStudent(pageNo, pageSize,id) {
        return axios.get(`/Api/Admin/Students/Get?pageNo=${pageNo}&pagesize=${pageSize}&id=${id}`);
    },

    GetStudentPhone(code) {
        return axios.get(`/Api/Admin/Students/GetStudentPhone?code=${code}`);
    },

    GetStudentByStudentName(code) {
        return axios.get(`/Api/Admin/Students/GetStudentByStudentName?code=${code}`);
    },

    //Getstudents() {
    //    return axios.get(`/api/admin/Students/GetNames`);
    //},
    GetPhoneStudents() {
        return axios.get(`/api/admin/Students/GetPhoneStudents`);
    },
    AddStuent(bodyObject) {
        return axios.post('/Api/Admin/Students/Add', bodyObject);
    },

    EditStudent(bodyObject) {
        return axios.post('/Api/Admin/Students/Edit', bodyObject);
    },

    GetByName(pageNo, pageSize,Id) {
        return axios.get(`/Api/Admin/Students/GetByName?pageNo=${pageNo}&pagesize=${pageSize}&Id=${Id}`);
    },
    GetByPhone(pageNo, pageSize, Id) {
        return axios.get(`/Api/Admin/Students/GetByPhone?pageNo=${pageNo}&pagesize=${pageSize}&Id=${Id}`);
    },
    DeleteStudent(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/Delete`);
    },
    ResetStudentPassword(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/ResetPassword`);
    },

    
    //********************************************************************| Stuents Wallet |***********************************************************
    GetStudentWallet(id) {
        return axios.get(`/Api/Admin/Students/GetWalletInfo?id=${id}`);
    },
    GetStudentWalletPurchases(id) {
        return axios.get(`/Api/Admin/Students/GetWalletPurchasesInfo?id=${id}`);
    },

    RechargeWallet(bodyObject) {
        return axios.post('/Api/Admin/Students/RechargeWallet', bodyObject);
    },

    DeleteStudentWalletTransacitons(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/DeletetWalletTransacitons`);
    },

    //********************************************************************| Stuents Course |***********************************************************
    GetStudentCourse(id) {
        return axios.get(`/Api/Admin/Students/GetStudentCourse?id=${id}`);
    },

    AddStudentCourse(bodyObject) {
        return axios.post('/Api/Admin/Students/AddStudentCourse', bodyObject);
    },

    PayStudentCourse(bodyObject) {
        return axios.post('/Api/Admin/Students/PayStudentCourse', bodyObject);
    },

    OutFromStudentCourse(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/OutFromStudentCourse`);
    },


    //********************************************************************| Stuents Subscribtions |***********************************************************
    GetStudentsSubscriptions(pageNo, pageSize, AcademicLevelId, AcademicSpecializationId, SubjectId, CourseId, studentId, SubscriptionType) {
        return axios.get(`/Api/Admin/Students/Subscriptions/Get?pageNo=${pageNo}&pagesize=${pageSize}
            &AcademicLevelId=${AcademicLevelId}&AcademicSpecializationId=${AcademicSpecializationId}
            &SubjectId=${SubjectId}&CourseId=${CourseId}&studentId=${studentId}&SubscriptionType=${SubscriptionType}`);
    },


    //********************************************************************| Stuents Suspend |***********************************************************
    SuspendStudent(bodyObject) {
        return axios.post('/Api/Admin/Students/Suspend/Add', bodyObject);
    },

    GetStudentsSuspend(pageNo, pageSize,studentId) {
        return axios.get(`/Api/Admin/Students/Suspend/Get?pageNo=${pageNo}&pagesize=${pageSize}&studentId=${studentId}`);
    },

    UnSuspendStudent(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/Suspend/UnSuspend`);
    },

    ActiveAcount(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/ActiveAcount`);
    },



    //********************************************************************| Stuents Change Request |***********************************************************
    //SuspendStudent(bodyObject) {
    //    return axios.post('/Api/Admin/Students/Suspend/Add', bodyObject);
    //},

    GetChangeDeviceRequest(pageNo, pageSize, studentId) {
        return axios.get(`/Api/Admin/Students/ChangeRequests/Get?pageNo=${pageNo}&pagesize=${pageSize}&studentId=${studentId}`);
    },

    ApproveChangeDeviecRequest(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/ChangeRequests/Approve`);
    },

    RejectChangeDeviecRequest(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/ChangeRequests/Reject`);
    },

    ResetDeviecInfo(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/ChangeRequests/ResetDeviecInfo`);
    },

    ResetLastLoginFrom(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/ChangeRequests/ResetLastLoginFrom`);
    },




    //********************************************************************| Stuents Regerster Request |***********************************************************
    //SuspendStudent(bodyObject) {
    //    return axios.post('/Api/Admin/Students/Suspend/Add', bodyObject);
    //},

    GetRegesterCourseRequest(pageNo, pageSize,CourseId, studentId) {
        return axios.get(`/Api/Admin/Students/RegesterCourse/Get?pageNo=${pageNo}&pagesize=${pageSize}&CourseId=${CourseId}&studentId=${studentId}`);
    },

    ApproveRegesterCourseRequest(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/RegesterCourse/Approve`);
    },

    RejectRegesterCourseRequest(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/RegesterCourse/Reject`);
    },


    DeleteRegesterCourseRequest(Id) {
        return axios.post(`/Api/Admin/Students/${Id}/RegesterCourse/Delete`);
    },







    //********************************************************************| Courses |***********************************************************
    AddCourses(bodyObject) {    
        return axios.post('/Api/Admin/Courses/Add', bodyObject);
    },

    GetCourses(pageNo, pageSize, AcademicLevelId, AcademicSpecializationId, SubjectId, CourseId) {
        return axios.get(`/Api/Admin/Courses/Get?pageNo=${pageNo}&pagesize=${pageSize}
                        &AcademicLevelId=${AcademicLevelId}&AcademicSpecializationId=${AcademicSpecializationId}
                        &SubjectId=${SubjectId}&CourseId=${CourseId}`);
    },

    EditCourses(bodyObject) {
        return axios.post('/Api/Admin/Courses/Edit', bodyObject);
    },

    TransferCourses(bodyObject) {
        return axios.post('/Api/Admin/Courses/TransferCourses', bodyObject);
    },

    DeleteCourses(Id) {
        return axios.post(`/Api/Admin/Courses/${Id}/Delete`);
    },

    StopCourses(Id) {
        return axios.post(`/Api/Admin/Courses/${Id}/StopCourses`);
    },

    ActiveCourses(Id) {
        return axios.post(`/Api/Admin/Courses/${Id}/ActiveCourses`);
    },

    CoursesChangeStatus(Id) {
        return axios.post(`/Api/Admin/Courses/${Id}/ChangeStatus`);
    },

    CoursesAccept(Id) {
        return axios.post(`/Api/Admin/Courses/${Id}/Accept`);
    },

    CoursesReject(Id) {
        return axios.post(`/Api/Admin/Courses/${Id}/Reject`);
    },

    ChangeCourseProfilePicture(bodyObject) {
        return axios.post('/Api/Admin/Courses/ChangeProfilePicture', bodyObject);
    },




    //********************************************************************| Courses Exams |***********************************************************
    AddCourseExam(bodyObject) {
        return axios.post('/Api/Admin/Courses/Exam/Add', bodyObject);
    },

    EditCourseExam(bodyObject) {
        return axios.post('/Api/Admin/Courses/Exam/Edit', bodyObject);
    },

    DeleteCourseExam(Id) {
        return axios.post(`/Api/Admin/Courses/${Id}/DeleteCourseExam`);
    },

    ChangeStatusCourseExam(Id) {
        return axios.post(`/Api/Admin/Courses/${Id}/ChangeStatusCourseExam`);
    },

    AddCourseExamQuestion(bodyObject) {
        return axios.post('/Api/Admin/Courses/Exam/AddQuestion', bodyObject);
    },

    GetCourseExams(Id) {
        return axios.get(`/Api/Admin/Courses/Exam/Get?ShapterId=${Id}`);
    },

    GetStudentExamInfo(Id) {
        return axios.get(`/Api/Admin/Courses/Exam/GetExamStudentInfo?ExamId=${Id}`);
    },

    GetCourseExamsQuestion(Id) {
        return axios.get(`/Api/Admin/Courses/Exam/GetQuestions?ExamId=${Id}`);
    },

    DeleteCoursesExamsQuestion(Id) {
        return axios.post(`/Api/Admin/Courses/${Id}/DeleteCourseExamQuestions`);
    },

    //EditCourses(bodyObject) {
    //    return axios.post('/Api/Admin/Courses/Edit', bodyObject);
    //},

    //DeleteCourses(Id) {
    //    return axios.post(`/Api/Admin/Courses/${Id}/Delete`);
    //},



    //********************************************************************| Courses Student |***********************************************************
    //InstractorAddCourses(bodyObject) {
    //    return axios.post('/Api/Admin/Courses/Add', bodyObject);
    //},

    GetCourseStudent(CourseId) {
        return axios.get(`/Api/Admin/Courses/Student/Get?CourseId=${CourseId}`);
    },

    GetCourseReview(CourseId) {
        return axios.get(`/Api/Admin/Courses/Review/Get?CourseId=${CourseId}`);
    },

    DeleteRivew(Id) {
        return axios.post(`/Api/Admin/Courses/${Id}/Review/Delete`);
    },

    //InstractorEditCourses(bodyObject) {
    //    return axios.post('/Api/Admin/Courses/Edit', bodyObject);
    //},

    //InstractorDeleteCourses(Id) {
    //    return axios.post(`/Api/Admin/Courses/${Id}/Delete`);
    //},



    //********************************************************************| Instractor Courses |***********************************************************
    InstractorAddCourses(bodyObject) {
        return axios.post('/Api/Admin/Courses/Add', bodyObject);
    },

    InstractorGetCourses(pageNo, pageSize, AcademicLevelId, AcademicSpecializationId, SubjectId, CourseId) {
        return axios.get(`/Api/Admin/Courses/Instractor/Get?pageNo=${pageNo}&pagesize=${pageSize}
                        &AcademicLevelId=${AcademicLevelId}&AcademicSpecializationId=${AcademicSpecializationId}
                        &SubjectId=${SubjectId}&CourseId=${CourseId}`);
    },

    InstractorEditCourses(bodyObject) {
        return axios.post('/Api/Admin/Courses/Edit', bodyObject);
    },

    InstractorDeleteCourses(Id) {
        return axios.post(`/Api/Admin/Courses/${Id}/Delete`);
    },








    //********************************************************************| Financial Info |***********************************************************

    GetSubscriptionsReports(pageNo, pageSize, SelectedDate) {
        return axios.get(`/Api/Admin/Financial/Report/GetSubscriptions?pageNo=${pageNo}&pagesize=${pageSize}
            &SelectedDate=${SelectedDate}`);
    },

    GetRechargeReports(pageNo, pageSize, SelectedDate, UserId, DistributorsId, PaymentMethodsId) {
        return axios.get(`/Api/Admin/Financial/Report/GetRecharge?pageNo=${pageNo}&pagesize=${pageSize}
            &SelectedDate=${SelectedDate}&UserId=${UserId}&DistributorsId=${DistributorsId}&PaymentMethodsId=${PaymentMethodsId}`);
    },






    //********************************************************************| Contact Us Info |***********************************************************

    GetContactUs(pageNo, pageSize) {
        return axios.get(`/Api/Admin/Settings/ContactUs/Get?pageNo=${pageNo}&pagesize=${pageSize}`);
    },









    //********************************************************************| VoucherCards |***********************************************************
    GetVoucherCards(pageNo, pageSize) {
        return axios.get(`/Api/Admin/VoucherCards/Get?pageNo=${pageNo}&pagesize=${pageSize}`);
    },

    GetCardInfo(SerialNumber) {
        return axios.get(`/Api/Admin/VoucherCards/GetCardInfo?SerialNumber=${SerialNumber}`);
    },

    AddVoucherCards(bodyObject) {
        return axios.post('/Api/Admin/VoucherCards/Add', bodyObject);
    },

    SaleVoucherCards(Id) {
        return axios.post(`/Api/Admin/VoucherCards/${Id}/Sale`, { responseType: 'blob' });
    },

    ForzePackage(Id) {
        return axios.post(`/Api/Admin/VoucherCards/${Id}/Forze`, { responseType: 'blob' });
    },






    GetDistributors(pageNo, pageSize) {
        return axios.get(`/Api/Admin/VoucherCards/Distributors/Get?pageNo=${pageNo}&pagesize=${pageSize}`);
    },

    GetAllDistributors() {
        return axios.get(`/Api/Admin/VoucherCards/Distributors/GetAll`);
    },

    ActivePackgeDistributors(bodyObject) {
        return axios.post('/Api/Admin/VoucherCards/Distributors/ActivePackge', bodyObject);
    },

    GetDistributorsPackages(pageNo, pageSize) {
        return axios.get(`/Api/Admin/VoucherCards/Distributors/Packages/Get?pageNo=${pageNo}&pagesize=${pageSize}`);
    },



    





























































    ChangePasswords(object) {

        return axios.post(`/Api/Admin/User/ChangePassword`, object);
    },







    GetUsers(pageNo, pageSize/*, UserType, municipalitieId, hostpitalId*/) {
        return axios.get(`/Api/Admin/User/Get?pageNo=${pageNo}&pagesize=${pageSize}`);
    },

    GetUserName() {
        return axios.get(`/Api/Admin/User/GetNames`);
    },

    AddUser(bodyObject) {
        return axios.post('/Api/Admin/User/Add', bodyObject);
    },
    EditUser(bodyObject) {
        return axios.post('/Api/Admin/User/Edit', bodyObject);
    },

    ChangeStatusUser(Id) {
        return axios.post(`/Api/Admin/User/${Id}/ChangeStatus`);
    },

    DeleteUser(Id) {
        return axios.post(`/Api/Admin/User/${Id}/Delete`);
    },

    UploadImage(bodyObject) {
        return axios.post('/Api/Admin/User/UploadImage', bodyObject);
    },

    ChangeProfilePicture(bodyObject) {
        return axios.post('/Api/Admin/User/ChangeProfilePicture', bodyObject);
    },

    EditUsersProfile(bodyObject) {
        return axios.post('/Api/Admin/User/EditUsersProfile', bodyObject);
    },

    EditPersonalInfo(bodyObject) {
        return axios.post('/Api/Admin/User/EditPersonalInfo', bodyObject);
    },

    EditContactInfo(bodyObject) {
        return axios.post('/Api/Admin/User/EditContactInfo', bodyObject);
    },

    ChangePassword(bodyObject) {
        return axios.post(`/Api/Admin/User/ChangePassword`, bodyObject);
    },

    AboutMeInfo(bodyObject) {
        return axios.post(`/Api/Admin/User/AboutMeInfo`, bodyObject);
    },


    RestePassword(Id) {
        return axios.post(`/Api/Admin/User/${Id}/RestePassword`);
    },








    // **********************************    Applications ***********************

    AddApplications(bodyObject) {
        return axios.post(`/Api/Admin/Applications/Add`, bodyObject);
    },

    EditApplications(bodyObject) {
        return axios.post(`/Api/Admin/Applications/Edit`, bodyObject);
    },

    GetApplications(pageNo, pageSize, MunicipalitId, SelectedHospitalsId) {
        return axios.get(`/Api/Admin/Applications/Get?pageno=${pageNo}&pagesize=${pageSize}&MunicipalitId=${MunicipalitId}&selectedHospitalsId=${SelectedHospitalsId}`);
    },

    deleteApp(id) {
        return axios.post(`/Api/Admin/Applications/${id}/delete`);
    },

    GetBranchesName(id) {
        return axios.get(`/Api/Admin/Applications/GetBranchesNames?cityId=${id}`);
    },

    GetBranchesAllName() {
        return axios.get(`/Api/Admin/Applications/GetAllBranchesNames`);
    },



    // **********************************    Hospitals ***********************

    GetHospitalsAllName(MunicipalitId) {
        return axios.get(`/Api/Admin/Hospitals/GetAllHospitalsNames?MunicipalitId=${MunicipalitId}`);
    },

    AddHospitals(bodyObject) {
        return axios.post(`/Api/Admin/Hospitals/Add`, bodyObject);
    },

    GetHospitals(pageNo, pageSize, MunicipalitId) {
        return axios.get(`/Api/Admin/Hospitals/Get?pageno=${pageNo}&pagesize=${pageSize}&MunicipalitId=${MunicipalitId}`);
    },

    EditHospitals(bodyObject) {
        return axios.post(`/Api/Admin/Hospitals/Edit`, bodyObject);
    },

    deleteHospitals(id) {
        return axios.post(`/Api/Admin/Hospitals/${id}/delete`);
    },


    //GetDashboardInfo() {
    //    return axios.get(`/Api/Admin/Hospitals/GetDashboardInfo`);
    //},

    GetRequiment() {
        return axios.get(`/Api/Admin/Municipalities/GetRequiment`);
    },

    // **********************************    GetDevicesName ***********************

    GetDevicesNames() {
        return axios.get(`/Api/Admin/Devices/GetDevicesName`);
    },

    GetDevicesbyCompany(id) {
        return axios.get(`/Api/Admin/Devices/GetDevicesbyCompany?id=${id}`);
    },

    GetFilterNames(id) {
        return axios.get(`/Api/Admin/Devices/GetFilterName?id=${id}`);
    },

    GetAllFilterNames() {
        return axios.get(`/Api/Admin/Devices/GetAllNames`);
    },

    // **********************************   cities ***********************

    Get(pageNo, pageSize) {
        return axios.get(`/Api/admin/cities/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    Add(bodyObject) {
        return axios.post(`/Api/Admin/cities/Add`, bodyObject);
    },
    Edit(bodyObject) {
        return axios.post(`/api/admin/cities/Edit`, bodyObject);
    },

    Deletecities(id) {
        return axios.post(`/Api/Admin/cities/${id}/Delete`);
    },

    ChangeStatusCities(id) {
        return axios.post(`/Api/Admin/cities/${id}/ChangeStatus`);
    },

// **********************************   Municipalities ***********************

    Get1(pageNo, pageSize) {
        return axios.get(`/Api/admin/Municipalities/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

AddMunicipalitiess(bodyObject) {
    return axios.post(`/Api/admin/Municipalities/Add`, bodyObject);
},
    EditMunicipalities(bodyObject) {
    return axios.post(`/Api/admin/Municipalities/Edit`, bodyObject);
},

    DeleteMunicipalities(id) {
    return axios.post(`/Api/admin/Municipalities/${id}/Delete`);
},

    ChangeStatusMunicipalities(id) {
    return axios.post(`/Api/admin/Municipalities/${id}/ChangeStatus`);
    },
    GetCities() {
     return axios.get(`/api/admin/Municipalities/GetCities`);
    },
    // **********************************   Locations ***********************

    GetLocations(pageNo, pageSize) {
        return axios.get(`/Api/admin/Locations/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    AddLocations(bodyObject) {
        return axios.post(`/Api/admin/Locations/Add`, bodyObject);
    },
    EditLocations(bodyObject) {
        return axios.post(`/Api/admin/Locations/Edit`, bodyObject);
    },

    DeleteLocations(id) {
        return axios.post(`/Api/admin/Locations/${id}/Delete`);
    },

    ChangeStatusLocations(id) {
        return axios.post(`/Api/admin/Locations/${id}/ChangeStatus`);
    },
    GetCities1() {
        return axios.get(`/api/admin/Locations/GetCities`);
    },
    GetallMunicipalities(cityid) {
        return axios.get(`/api/admin/Locations/GetallMunicipalities?cityid=${cityid}`);
    },
    GetallMunicipalitiess(cityid) {
        return axios.get(`/api/admin/Locations/GetallMunicipalities?cityid=${cityid}`);
    },

 // **********************************   Subscriptions ***********************
    GetSubscriptions(pageNo, pageSize) {
        return axios.get(`/Api/admin/Subscriptions/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },

    AddSubscriptions(bodyObject) {
        return axios.post(`/Api/admin/Subscriptions/Add`, bodyObject);
    },
    EditSubscriptions(bodyObject) {
        return axios.post(`/Api/admin/Subscriptions/Edit`, bodyObject);
    },

    DeleteSubscriptions(id) {
        return axios.post(`/Api/admin/Subscriptions/${id}/Delete`);
    },

    ChangeStatusSubscriptions(id) {
        return axios.post(`/Api/admin/Subscriptions/${id}/ChangeStatus`);
    },


// **********************************   Instructors ***********************
    AddInstructorss(bodyObject) {
        return axios.post(`/Api/admin/Instructors/Add`, bodyObject);
    },
    GetInstructors(pageNo, pageSize) {
        return axios.get(`/Api/admin/Instructors/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },
    EditInstructors(bodyObject) {
        return axios.post(`/Api/admin/Instructors/Edit`, bodyObject);
    },
    DeleteInstructors(id) {
        return axios.post(`/Api/admin/Instructors/${id}/Delete`);
    },

    ChangeStatusInstructors(id) {
        return axios.post(`/Api/admin/Instructors/${id}/ChangeStatus`);
    },
    Addcenters(bodyObject) {
        return axios.post(`/Api/admin/Instructors/AddCenter`, bodyObject);
    },
    Getcenters(pageNo, pageSize) {
        return axios.get(`/Api/admin/Instructors/GetCenter?pageno=${pageNo}&pagesize=${pageSize}`);
    },
    Editcenters(bodyObject) {
        return axios.post(`/Api/admin/Instructors/EditCenter`, bodyObject);
    },
    Deletecenters(id) {
        return axios.post(`/Api/admin/Instructors/${id}/DeleteCenter`);
    },
    GetSubject() {
        return axios.get(`/api/admin/Instructors/GetSubjects`);
    },
    AddCourse(bodyObject) {
        return axios.post(`/Api/admin/Instructors/AddCourses`, bodyObject);
    },
    Getcourse(pageNo, pageSize, InstructorId) {
        return axios.get(`/Api/admin/Instructors/GetCourses?pageno=${pageNo}&pagesize=${pageSize}&InstructorId=${InstructorId}`);
    },
    Getcourses(pageNo, pageSize, InstructorId) {
        return axios.get(`/Api/admin/Instructors/GetCourses2?pageno=${pageNo}&pagesize=${pageSize}&InstructorId=${InstructorId}`);
    },
    Editcourses(bodyObject) {
        return axios.post(`/Api/admin/Instructors/EditCourses`, bodyObject);
    },
    Deletecourse(id) {
        return axios.post(`/Api/admin/Instructors/${id}/DeleteCourses`);
    },
    Accepted(id) {
        return axios.post(`/Api/admin/Instructors/${id}/Accept`);
    },
    Rejected(bodyObject) {
        return axios.post(`/Api/admin/Instructors/Reject`, bodyObject);
    },


    //********************************************************************| Instructors Suspend |***********************************************************
    //SuspendStudent(bodyObject) {
    //    return axios.post('/Api/Admin/Students/Suspend/Add', bodyObject);
    //},

    GetInstructorsSuspend(pageNo, pageSize) {
        return axios.get(`/Api/Admin/Instructors/Suspend/Get?pageNo=${pageNo}&pagesize=${pageSize}`);
    },

    //UnSuspendStudent(Id) {
    //    return axios.post(`/Api/Admin/Students/${Id}/Suspend/UnSuspend`);
    //},
    //********************************************************************| Instructors Requests |***********************************************************

    GetInstructorsRequests(pageNo, pageSize) {
        return axios.get(`/Api/Admin/Instructors/Request/Get?pageNo=${pageNo}&pagesize=${pageSize}`);
    },
    AcceptedRequests(id) {
        return axios.post(`/Api/admin/Instructors/${id}/Request/Accept`);
    },
    RejectedRequests(bodyObject) {
        return axios.post(`/Api/admin/Instructors/Request/Reject`, bodyObject);
    },

    //********************************** InstructorsLicense ***********************
    GetInstructor() {
        return axios.get(`/api/admin/InstructorsLicense/GetInstructors`);
    },
    GetSubscription() {
        return axios.get(`/api/admin/InstructorsLicense/GetSubscriptions`);
    },
    AddInstructorsLicense(bodyObject) {
        return axios.post(`/Api/admin/InstructorsLicense/Add`, bodyObject);
    },
    GetValue(id) {
        return axios.get(`/api/admin/InstructorsLicense/GetValue?id=${id}`);
    },
    GetInstructorsLicense(pageNo, pageSize) {
        return axios.get(`/Api/admin/InstructorsLicense/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },
    UploadImages(bodyObject) {
        return axios.post(`/Api/admin/Instructors/UploadImage`, bodyObject);
    },
    EditInstructorsLicense(bodyObject) {
        return axios.post(`/Api/admin/InstructorsLicense/Edit`, bodyObject);
    },
    DeleteInstructorsLicense(id) {
        return axios.post(`/Api/admin/InstructorsLicense/${id}/Delete`);
    },
   
        //********************************** Shapters ***********************
    GetShapters(pageNo, pageSize, CourseId) {
        return axios.get(`/Api/admin/Shapters/Get?pageno=${pageNo}&pagesize=${pageSize}&CourseId=${CourseId}`);
    },

    GetCourseShapters(CourseId) {
        return axios.get(`/Api/admin/Shapters/GetByCourseId?CourseId=${CourseId}`);
    },

    AddShapters(bodyObject) {
        return axios.post(`/Api/Admin/Shapters/Add`, bodyObject);
    },
    EditShapters(bodyObject) {
        return axios.post(`/api/admin/Shapters/Edit`, bodyObject);
    },

    DeleteShapters(id) {
        return axios.post(`/Api/Admin/Shapters/${id}/Delete`);
    },

    ChangeStatusShapters(id) {
        return axios.post(`/Api/Admin/Shapters/${id}/ChangeStatus`);
    },

    //********************************** Lectures ***********************
    GetLectures(pageNo, pageSize, ShapterId) {
        return axios.get(`/Api/admin/Lectures/Get?pageno=${pageNo}&pagesize=${pageSize}&ShapterId=${ShapterId}`);
    },

    OpenVideo(Id) {
        return axios.get(`/Api/admin/Lectures/ShowVideo?Id=${Id}`);
    },
    GetShapterLectures(ShapterId) {
        return axios.get(`/Api/admin/Lectures/GetByShapterId?ShapterId=${ShapterId}`);
    },
    GetLecturesAttachments(LectureId) {
        return axios.get(`/Api/admin/Lectures/GetLectureAttachment?LectureId=${LectureId}`);
    },
    GetVideoStatus(VideoId) {
        return axios.get(`/Api/admin/Lectures/GetVideoStatus?VideoId=${VideoId}`);
    },
    AddLectures(bodyObject) {
        return axios.post(`/Api/Admin/Lectures/Add`, bodyObject);
    },
    AddLecturesAttachments(bodyObject) {
        return axios.post(`/Api/Admin/Lectures/AddAttachment`, bodyObject);
    },
    AddLecturesAttachmentsManual(bodyObject) {
        return axios.post(`/Api/Admin/Lectures/AddAttachmentManul`, bodyObject);
    },
    EditLectures(bodyObject) {
        return axios.post(`/api/admin/Lectures/Edit`, bodyObject);
    },

    DeleteLectures(id) {
        return axios.post(`/Api/Admin/Lectures/${id}/Delete`);
    },
    DeleteLecturesAttachment(id) {
        return axios.post(`/Api/Admin/Lectures/${id}/RemoveLecturesAttachment`);
    },

    ChangeStatusLectures(id) {
        return axios.post(`/Api/Admin/Lectures/${id}/ChangeStatus`);
    },
    //********************************** Attashments ***********************
    GetAttashments(pageNo, pageSize, LectureId) {
        return axios.get(`/Api/admin/Lectures/GetLectureAttachment?pageno=${pageNo}&pagesize=${pageSize}&LectureId=${LectureId}`);
    },
    AddAttashments(bodyObject) {
        return axios.post(`/Api/Admin/Lectures/AddLectureAttachment`, bodyObject);
    },
    EditAttashments(bodyObject) {
        return axios.post(`/api/admin/Lectures/EditLectureAttachment`, bodyObject);
    },

    DeleteAttashments(id) {
        return axios.post(`/Api/Admin/Lectures/${id}/RemoveLecturesAttachment`);
    },

    ChangeAttashments(id) {
        return axios.post(`/Api/Admin/Lectures/${id}/ChangeStatusLectureAttashments`);
    },
    //********************************** AcademicLevels ***********************

    GetAcademicLevels(pageNo, pageSize) {
        return axios.get(`/Api/admin/AcademicLevels/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },
    AddAcademicLevels(bodyObject) {
        return axios.post(`/Api/Admin/AcademicLevels/Add`, bodyObject);
    },
    EditAcademicLevels(bodyObject) {
        return axios.post(`/api/admin/AcademicLevels/Edit`, bodyObject);
    },

    DeleteAcademicLevels(id) {
        return axios.post(`/Api/Admin/AcademicLevels/${id}/Delete`);
    },

    ChangeAcademicLevels(id) {
        return axios.post(`/Api/Admin/AcademicLevels/${id}/ChangeStatus`);
    },

    ChangeAcadimecLevelProfilePicture(bodyObject) {
        return axios.post(`/api/admin/AcademicLevels/ChangeProfilePicture`, bodyObject);
    },

    

    //********************************** AcademicSpecialization ***********************

    GetAcademicSpecialization(pageNo, pageSize) {
        return axios.get(`/Api/admin/AcademicLevels/AcademicSpecialization/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },
    AddAcademicSpecialization(bodyObject) {
        return axios.post(`/Api/Admin/AcademicLevels/AcademicSpecialization/Add`, bodyObject);
    },
    EditAcademicSpecialization(bodyObject) {
        return axios.post(`/api/admin/AcademicLevels/AcademicSpecialization/Edit`, bodyObject);
    },

    ChangeAcademicSpecializationProfilePicture(bodyObject) {
        return axios.post(`/api/admin/AcademicLevels/AcademicSpecialization/ChangeProfilePicture`, bodyObject);
    },


    DeleteAcademicSpecialization(id) {
        return axios.post(`/Api/Admin/AcademicLevels/AcademicSpecialization/${id}/Delete`);
    },

    ChangeAcademicSpecialization(id) {
        return axios.post(`/Api/Admin/AcademicLevels/AcademicSpecialization/${id}/ChangeStatus`);
    },
    //********************************** Subjects ***********************

    GetSubjects(pageNo, pageSize) {
        return axios.get(`/Api/admin/AcademicLevels/Subjects/Get?pageno=${pageNo}&pagesize=${pageSize}`);
    },
    AddSubjects(bodyObject) {
        return axios.post(`/Api/Admin/AcademicLevels/Subjects/Add`, bodyObject);
    },
    EditSubjects(bodyObject) {
        return axios.post(`/api/admin/AcademicLevels/Subjects/Edit`, bodyObject);
    },

    DeleteSubjects(id) {
        return axios.post(`/Api/Admin/AcademicLevels/Subjects/${id}/Delete`);
    },

    ChangeSubjects(id) {
        return axios.post(`/Api/Admin/AcademicLevels/Subjects/${id}/ChangeStatus`);
    },
    
}