import Swal from 'sweetalert2'
import moment from 'moment';
import flatPickr from "vue-flatpickr-component";
import HelperMixin from '../../Shared/HelperMixin.vue'
export default {

    name: 'Instructors',
    mixins: [HelperMixin],
    async created() {
        await this.CheckLoginStatus();
        if (this.loginDetails.userType != 1)
            this.logout();

        await this.GetCities();
        this.GetInfo();
      
        //this.GetallMunicipalities() 
        //this.GetallLocations() 
    },
    components: {
        flatPickr,
    },
    filters: {
        moment: function (date) {
            if (date === null) {
                return "فارغ";
            }
            return moment(date).format('MMMM Do YYYY');
        }
    },
    data() {
        return {
            showMenu: false,
            activeClass: "js-active position-relative",
            activeStep: 0,
            formSteps: 3,
            config: {
                allowInput: true,
            },

            pageNo: 1,
            pageSize: 10,
            pages: 0,
            state: 0,
            Info: [],

            imageUrl: '',

            Cities: [],
            Instructors: [],
            MunicipalityId: [],
            Locations: [],
            LocationId: [],

            ruleForm: {  
                FirstName: '',
                FatherName:'',
                GrandFateherName: '',
                SirName: '',
                LoginName:'',
                Gender: '',
                BirthDate: '',
                Phone: '',
                ExtraPhone: '',
                Email: '',
                Facebook: '',
                Password: '',
                ConfirmPassword: '',
                CityId: '',
                MunicipalityId: '',
                LocationId: '',
             
            },  
            form: {
                FirstName: '',
                FatherName: '',
                GrandFateherName: '',
                SirName: '',
                LoginName: '',
                Gender: '',
                BirthDate: '',
                Phone: '',
                ExtraPhone: '',
                Email: '',
                Facebook: '',
            },

            
            rules: {
                CityId: this.$helper.Required(),
                MunicipalityId: this.$helper.Required(),
                LocationId: this.$helper.Required(),
                FirstName: this.$helper.DynamicArabicEnterRequired(' الاسم الاول'),
                FatherName: this.$helper.DynamicArabicEnterRequired(' اسم الاب'),
                GrandFateherName: this.$helper.DynamicArabicEnterRequired(' اسم الجد'),
                SirName: this.$helper.DynamicArabicEnterRequired(' اللقب'),
                Gender: this.$helper.Required(),
                BirthDate: this.$helper.Required(),
                Phone: this.$helper.NumberOnly(),
                Email: this.$helper.EmailOnly(),
                Password: this.$helper.Required(),
                ConfirmPassword: this.$helper.Required(),
                LoginName: this.$helper.Required(),

            },

        };
    },
    watch: {
        'ruleForm.CityId'(){
            this.GetallMunicipalitiess();
            this.ruleForm.MunicipalityId = '';
        }
    },

    methods: {

        //FileChanged(e) {
        //    var files = e.target.files;
        //    if (files.length <= 0) {
        //        return;
        //    }
        //    //if (files[0].type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        //    //    this.ShowMessage("خطأ بعملية الاضافة", "الرجاء التأكد من الملف ", "warning");
        //    //    this.photo = null;
        //    //    window.location.href = '/Attachments/Supplier Item .xlsx'
        //    //    return;
        //    //}
        //    var $this = this;
        //    var reader = new FileReader();
        //    reader.onload = function () {
        //        var obj =
        //        {
        //            fileName: files[0].name,
        //            fileBase64: reader.result,
        //        };
        //        $this.UploadImage(obj);
        //    };
        //    reader.onerror = function () {
        //        $this.photo = null;
        //    };
        //    reader.readAsDataURL(files[0]);
        //},




        //UploadImage(obj) {
        //    this.$blockUI.Start();
        //    this.$http.UploadImages(obj)
        //        .then(response => {
        //            this.$blockUI.Stop();
        //            this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
        //            //this.GetInfo();
        //        })
        //        .catch((err) => {
        //            this.$blockUI.Stop(err);
        //            this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
        //            //this.ShowMessage("خطأ بعملية الاضافة", err.response.data, "warning");
        //        });
        //},



        OpenAddItem() {
            this.state = 1;
            this.Clear();
        },

        submitForm(formName) {
            
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.AddInstructors(formName);
                } else {
                    this.$helper.showSwal('warning');
                    return false;
                }
            });
        },
      
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },

        AddInstructors(formName) { 
            this.$blockUI.Start();
            this.$http.AddInstructorss(this.ruleForm)
         
                .then(response => {
                    this.$blockUI.Stop();
                    this.resetForm(formName);
                    this.$helper.ShowMessage('success', 'عملية ناجحة', response.data);
                    this.state = 0;
                    this.GetInfo();
                    this.Clear();
                })
                .catch((err) => {
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية الاضافة', err.response.data);
                });
        },

        GetInfo(pageNo) {
            this.pageNo = pageNo;
            if (this.pageNo === undefined) {
                this.pageNo = 1;
            }
            this.$blockUI.Start();
            this.$http.GetInstructors(this.pageNo, this.pageSize)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Info = response.data.info;
                    this.pages = response.data.count;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                    this.pages = 0;
                });
        },

        GetCities() {
            
            this.CityId = [];
            this.CityId = '';
            this.$blockUI.Start();
            this.$http.GetCities1(this.ruleForm.MunicipalityId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Cities = response.data.info;
                })
                .catch (() => {
                    this.$blockUI.Stop();
                    
                })
        },
       
        GetallMunicipalities(id = '') {
            this.GetLocationsName();
            this.ruleForm.LocationId = '';
            this.Municipalities = [];
            this.MunicipalityId = '';
            this.$blockUI.Start();
            this.$http.GetallMunicipalities(this.ruleForm.CityId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Municipalities = response.data.info;
                    this.ruleForm.MunicipalityId = id;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                })
        },

        GetallMunicipalitiess() {
         
            this.GetLocationsName();
            this.ruleForm.LocationId = '';
            this.Municipalities = [];
            this.MunicipalityId = '';
            this.$blockUI.Start();
            this.$http.GetallMunicipalitiess(this.ruleForm.CityId)
                .then(response => {
                    this.$blockUI.Stop();
                    this.Municipalities = response.data.info;
                })
                .catch(() => {
                    this.$blockUI.Stop();
                })
        },
        //GetallLocations() {
        //    this.LocationId = [];
        //    this.LocationId = '';
        //    this.$blockUI.Start();
        //    this.$http.GetallLocation(this.ruleForm.MunicipalityId)
        //        .then(response => {
        //            this.$blockUI.Stop();
        //            this.Locations = response.data.info;
        //        })
        //        .catch(() => {
        //            this.$blockUI.Stop();
        //        })
        //},

        async GetLocationsName() {
            this.ruleForm.LocationId = '';
            await this.GetLocations(this.ruleForm.MunicipalityId)
        },

        Deleteitem(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية الحذف ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.DeleteInstructors(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.$blockUI.Stop();
                                this.GetInfo();
                            });


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية الحذف',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;

                }
            })
        },

        ChangeStatus(id) {
            Swal.fire({
                title: 'هـل انت متأكد من عملية تغير الحالة ؟',
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `تأكيد العملية`,
                denyButtonText: `الغاء العملية`,
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$blockUI.Start();
                    this.$http.ChangeStatusInstructors(id)
                        .then(response => {
                            this.$blockUI.Stop();
                            this.FormPorgress = 100;
                            Swal.fire({
                                icon: 'success',
                                title: '..نجـاح العملية',
                                html:
                                    response.data,
                                showCancelButton: true,
                                denyButtonText: `خروج`,
                            }).then(() => {
                                this.$blockUI.Stop();
                                this.GetInfo();
                            });


                        })
                        .catch((err) => {
                            this.$blockUI.Stop();
                            this.$notify({
                                title: 'خطأ بعملية تغير الحالة',
                                dangerouslyUseHTMLString: true,
                                type: 'error',
                                message: err.response.data
                            });
                        });
                    return;

                }
            })
        },

        EditItem(item) {
            this.form.Id = item.id;
            this.selectedItem = item;
            this.ruleForm.CityId = item.cityId;
            /*this.ruleForm.MunicipalityId = item.municipalityId;*/
            this.form.FirstName = item.firstName;
            this.form.FatherName = item.fatherName;
            this.form.GrandFateherName = item.grandFateherName;
            this.form.SirName = item.sirName;

            this.form.LoginName = item.userInfo.loginName;
            this.form.Gender = item.userInfo.gender;
            this.form.BirthDate = item.userInfo.birthDate;
            this.form.Facebook = item.facebook;

            this.form.Phone = item.userInfo.phone;
            this.form.ExtraPhone = item.userInfo.extraPhone;
            this.form.Email = item.userInfo.email;
            //this.ruleForm.Password = item.userInfo.password;
            this.ruleForm.LocationId = item.userInfo.locationId;
            this.GetallMunicipalities(item.municipalityId);
            this.state = 2;
        },

        SubmitEditForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.Edit(formName);
                } else {
                    return false;
                }
            });
        },

        Edit(formName) {
            
            this.$blockUI.Start();
            this.$http.EditInstructors(this.form)
                .then(response => {
                    this.resetForm(formName);
                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('success', 'نجاح العملية', response.data);
                    this.state = 0;
                    this.GetInfo();
                    this.Clear2();

                })

                .catch((err) => {

                    this.$blockUI.Stop();
                    this.$helper.ShowMessage('error', 'خطأ بعملية التعديل', err.response.data);
                });

        },

        Clear() {
            this.ruleForm.CityId = '';
            this.ruleForm.MunicipalityId = '';
            this.ruleForm.LocationId = '';
            this.ruleForm.FirstName = '';
            this.ruleForm.FatherName = '';
            this.ruleForm.GrandFateherName = '';
            this.ruleForm.SirName = '';
            this.ruleForm.LoginName = '';

            this.ruleForm.Gender = '';
            this.ruleForm.BirthDate = '';
            this.ruleForm.Phone = '';
            this.ruleForm.ExtraPhone = '';
            this.ruleForm.Email = '';
            this.ruleForm.Facebook = '';
            this.ruleForm.Password = '';

        },
        Clear2() {
            //this.form.CityId = '';
            //this.form.MunicipalityId = '';
            //this.form.LocationId = '';
            this.form.FirstName = '';
            this.form.FatherName = '';
            this.form.GrandFateherName = '';
            this.form.SirName = '';
            this.form.LoginName = '';

            this.form.Gender = '';
            this.form.BirthDate = '';
            this.form.Phone = '';
            this.form.ExtraPhone = '';
            this.form.Email = '';
            this.form.Facebook = '';
            //this.form.Password = '';

        },
    }
}
